/**
* Generated automatically at built-time (2024-12-19T10:40:41.353Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "2c33b35d-c190-48fb-a532-ae751645266d",templateKey: "sites/107-24f6adfe-fd68-447c-966b-f06682fdbf71"};