/**
* Generated automatically at built-time (2024-12-19T10:40:41.314Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "skeldervik-buitenkeukens",templateKey: "sites/107-2d8fc969-2f44-468c-9b88-87649a8178c3"};